.card,
.custom-swal,
.modal-content {
  background-color: #1c1e23;
  color: #fff;
}

.swal2-toast {
  background-color: #2e323a !important;
}

body,
html {
  color: #fff;
  /* background-color: #1c1e23; */
  background-color: #000000;
}

.pages-bg {
  background-size: cover;
  background-repeat: no-repeat;
}

.rc279-header {
  /* background-color: #252830; */
  background-color: #242424;
}

.btn-prim {
  background-color: #f5be09;
  color: #080808;
  padding: 0.5rem;
  &:hover {
    background-color: #f5be09;
    color: #080808;
  }
  &:disabled {
    background-color: #dcab08;
    color: #080808;
  }
}

.btn-prim-light {
  background-color: rgba(245, 190, 9, 0.05);
  color: #f5be09;
  border: 1px solid #f5be09;
  padding: 0.5rem;
  &:hover {
    background-color: #f5be09;
    color: #080808;
  }
  &:disabled {
    background-color: #dcab08;
    color: #080808;
  }
}

.btn-custom-light {
  background-color: #fff;
  color: #080808;
  padding: 0.5rem;
  &:hover {
    background-color: #fff;
    color: #080808;
  }
}

.scroll-y {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.scroll-y-default {
  overflow-y: auto;
}

.scroll-x {
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.fv-plugins-message-container {
  font-size: 0.85rem;
}

.form-control,
.form-select {
  &::placeholder {
    color: #a3a3a3;
  }

  &:focus {
    background-color: #1c1e23;
    color: #fff;
  }
  background-color: #1c1e23;
  border-color: #a3a3a3;
  color: #fff;
}

.phone-input {
  height: 38px !important;
  background-color: #1c1e23 !important;
  border-color: #a3a3a3 !important;
  color: #fff !important;
}

.divider {
  display: flex;
  align-items: center;
}

.divider::before,
.divider::after {
  flex: 1;
  content: "";
  padding: 0.01rem;
  background-color: #fff;
  margin: 5px;
}

.login-language {
  width: 30px;
  height: 30px;
  margin-right: 1rem;
  &:last-child {
    margin-right: 0rem;
  }
}

.ws-nowrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.text-prim {
  color: #f5be09;
}

.login-slide {
  background-size: cover;
  height: 25rem;
}

.step {
  height: 30px;
  width: 30px;
  background-color: #555;
  &.active {
    background-color: #f5be09;
    color: #080808;
  }
}

.steps {
  background: linear-gradient(
    to bottom,
    transparent calc(50% - 1px),
    #555 calc(50% - 1px) calc(50% + 1px),
    transparent calc(50% + 1px)
  );
}

.otp-field {
  width: 2.5rem !important;
  height: 3rem;
  font-size: 1.2rem;
  &::-moz-selection {
    background: transparent;
  }
  &::selection {
    background: transparent;
  }
}

.text-input-label {
  color: #eeeaff;
}

.currency-icon,
.language-icon {
  width: 30px;
  height: 30px;
}

.dropdown-menu {
  background-color: #212329;
  .dropdown-item {
    color: #eeeaff;
    &.active {
      background-color: #2e323d;
    }
    &:hover {
      background-color: #2d3038;
    }
  }
}

.text-placeholder {
  color: #a3a3a3 !important;
}

.flag-dropdown {
  background-color: #212329 !important;
  .selected-flag {
    background-color: #2d3038 !important;
  }
  &:hover {
    background-color: #2d3038 !important;
  }
  .country-list {
    background-color: #212329 !important;
    .country {
      &:hover {
        background-color: #2d3038 !important;
      }
      &.highlight {
        background-color: #2e323d !important;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.navbar-item {
  margin-right: 1.5rem;
  font-size: 1.25rem;
  &:hover {
    color: #e9b408;
  }
  &.active {
    color: #f5be09;
    border-bottom: 2px solid #f5be09;
  }
}

.navbar-icon {
  width: 50px;
  height: 50px;
}

.triangle-bottomright {
  width: 0;
  height: 0;
  border-bottom: 66px solid #f5be09;
  border-left: 20px solid transparent;
}

.navbar-wallet {
  background-color: #f5be09;
  color: #080808;
}

.home-game-type-bg {
  background-size: cover;
  height: 350px;
}

.home-game-item {
  background-image: linear-gradient(to right, #f5be09, #f8c006);
  padding: 1px;
  .home-game-item2 {
    background-image: linear-gradient(to right, #3d3d3c, #010101);
  }
}

.triangle-topleft {
  width: 0;
  height: 0;
  border-top: 43px solid #f8c006;
  border-right: 20px solid transparent;
  &.dark {
    border-top: 41px solid #010101;
    border-right: 19px solid transparent;
  }
}

.category-active {
  background-color: #f8c006;
  color: #080808;
}

.company-active {
  background: radial-gradient(#000, rgba(255, 255, 255, 0.1));
}

.menu-item.here.show {
  .main-menu-image div {
    background: #f8c006 !important;
  }
}

.menu-item.show {
  color: #f8c006;
  .main-menu-image div {
    background: #f8c006 !important;
  }
}

.menu-item {
  .main-menu-image {
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: no-repeat;
    mask-repeat: contain;
  }

  .main-menu-image div {
    width: 24px;
    height: 24px;
    &.lg {
      width: 40px;
      height: 40px;
    }
    background-color: #fff;
  }

  &:hover {
    color: #f8c006;
    .main-menu-image div {
      background: #f8c006 !important;
    }
  }
}

.text-muted {
  color: #a3a3a3 !important;
}

.custom-border {
  border: 1px solid #a3a3a3;
}

.border-prim {
  border: 1px solid #f8c0066c;
}

.custom-border-bottom {
  border-bottom: 1px solid #a3a3a3;
}

.custom-border-end {
  border-right: 1px solid #a3a3a3;
}

.smaller {
  font-size: 0.7rem;
}

.modal-close {
  color: #a3a3a3;
  &:hover {
    color: #c5c5c5;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.zoom-in-out-anim {
  animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

.switch {
  &.active {
    color: #080808;
    background: #f8c006;
  }
  &:hover {
    color: #fff;
    background-color: rgba(245, 190, 9, 0.5);
  }
}

.bank-active,
.point-shop-item {
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2),
    0 6px 20px 0 rgba(255, 255, 255, 0.19);
}

.icon-exchange {
  &:hover {
    color: #f8c006;
  }
}

.lottery-nav {
  border-bottom: 2px solid transparent;
  &.active {
    color: #f8c006;
    border-bottom: 2px solid #f8c006;
  }

  &:hover {
    border-bottom: 2px solid #c39e27;
  }
}

.lottery-head-cashweep {
  background-color: #408c04;
}

.lottery-head-damacai {
  background-color: #fff;
  color: #080808;
}

.lottery-head-gdlotto {
  background-color: #000;
  color: #f8c006;
}

.lottery-head-magnum {
  background-color: #ffc40c;
  color: #080808;
}

.lottery-head-sabah88 {
  background-color: #ffbcbc;
  color: #080808;
}

.lottery-head-sandakan {
  background-color: #10ac44;
}

.lottery-head-singapore {
  background-color: #000;
}

.lottery-head-toto {
  background-color: #ff0404;
}

.bg-prim {
  background-color: #ffa500 !important;
}

.bg-hover {
  background-color: #aaff0012;
}

.form-check-input:checked {
  background-color: #ffa500;
  border-color: #ffa500;
}
.form-check-input {
  --bs-form-check-bg: #080808;
  background-color: transparent;

  &:checked {
    background-color: #ffa500;
    border-color: #ffa500;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-check' viewBox='0 0 16 16'%3E%3Cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3E%3C/svg%3E");
  }
}

.spinning-animation {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#chat-widget-container {
  right: 1.5rem !important;
}

.bg-light-success {
  background-color: #d1ffbd !important;
}

.lottery-slip-item {
  &:hover {
    i {
      color: #ffa500;
    }
  }
}
